* {
  box-sizing: border-box;
  scrollbar-color: rgb(31, 33, 28) white;
}

@font-face{
  font-family: ibm; 
  src: url(/src/Assets/fonts/IBMPlexSans-Regular.ttf)
}
/*Preset Colours*/
:root{
  --bdr-l: rgb(75, 75, 105);
  --bdr-d: rgb(13, 17, 20);
  --res-unit: min(1vw, 2vh);
  --res-munit: max(1vw, 2vh);
}

body {
  margin: 0;
  font-family: 'ibm', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'ibm', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#wrapper{
  width: 100vw;
  height: 100vh;
  position: absolute;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  background-color: black;
}

#aocbox{
  background-color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
  top: 0%;
  color: aliceblue;
  font-size: calc(var(--res-unit)*2);
  padding: calc(var(--res-unit)*4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.AOCText{
  text-align: center;
}
#aocbutton{
  background-color: transparent;
  border: none;
  padding: 0 var(--res-unit);
  height: calc(var(--res-unit)*3);
  display: flex;
  align-content: center;
  color: aliceblue;
  transition: 0.1s;
  opacity: 1.0;
  font-size: calc(var(--res-unit)*2);
  line-height: calc(var(--res-unit)*3);
  align-items: center;
  justify-content: center;
  border: solid aliceblue calc(var(--res-unit)*0.1);
}
#aocbutton:hover{
 color: #B5D77F;
 cursor: pointer;
}
#downloadoverlay{
 background-color: rgba(0,0,0,0.7);
 width: 100%;
 height: 100%;
 z-index: 3;
 position: absolute;
 left: 0%;
 top: 0%;
}
#downloadbox{
 background-color: black;
 width: 70%;
 height: 70%;
 position: absolute;
 left: 15%;
 top: 15%;
 color: aliceblue;
 font-size: calc(var(--res-unit)*2);
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 align-items: center; 
 z-index: 4;
 padding: calc(var(--res-unit)*4);
 border: solid aliceblue calc(var(--res-unit)*0.1);
}
.downloadtext{
 text-align: center;
}
#loadingscreenbox{
 width: 100%;
 height: 100%;
 position: absolute;
 left: 0%;
 top: 0%;
 z-index: 1;
}
#loadingscreenbglayer1{
 background-image: url('./Assets/bm-swamp3.png');
 background-repeat: no-repeat;
 background-attachment: fixed;
 background-position: center;
 background-size: cover;
 image-rendering: auto;
 width: 100vw;
 height: 100vh;
 position: absolute;
 left: 0%;
 top: 0%;
}
.LoadingScreenBackGroundLayers{
  background-image: url('./Assets/bm-swamp3.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  width: calc(100% - var(--res-unit));
  height: calc(100% - var(--res-unit));
  position: relative;
  left: calc(var(--res-unit)/2);
  top: calc(var(--res-unit)/2);
}
#loadingscreenbglayer2{
 filter: brightness(0.8);
}
#loadingscreenbglayer3{
 filter: brightness(0.6);
}
#loadingscreenbglayer4{
 filter: brightness(0.4);
}
#loadingscreenbglayer5{
 filter: brightness(0.2);
}
.LoadingBarWrapper{
  position: absolute;
  width: calc(var(--res-unit)*3);
  height: calc(100% - calc(var(--res-unit)*6));
  top: calc(var(--res-unit)*3);  
}
.LoadingBarOuter{
  background-color: aliceblue;
  position: relative;
  width: 100%;
  transition: height 0.5s;
}
.LoadingBarInner{
  background-image: url('./Assets/pix-grad1.png');
  background-size: 500% 200%;
  mix-blend-mode: difference;
  position: absolute;
  top: calc(calc(var(--res-unit)*0.2)/2);
  left: calc(calc(var(--res-unit)*0.2)/2);
  width: calc(100% - calc(calc(var(--res-unit)*0.2)));
  height: calc(100% - calc(calc(var(--res-unit)*0.2)));
  z-index: 2;
}
.LoadingBarInnerbu{
  background-color: white;
  position: relative;
  top: calc(var(--res-unit)/4);
  left: calc(var(--res-unit)/4);
  width: calc(100% - calc(var(--res-unit)/2));
  height: calc(100% - calc(var(--res-unit)/2));
}
#loadingbarleft{
  left: calc(var(--res-unit)*3);
}
#loadingbarright{
  right: calc(var(--res-unit)*3);
}
.LoadingText{
  position: relative;
  top: -3%;
  font-size: calc(var(--res-unit)*1);
  width: 100%;
  text-align: center;
}

#loadingscreentextarea{
 background-color: rgba(0,0,0,0.7);
 position: absolute;
 max-width: calc(40% - calc(var(--res-unit)*8));
 height: calc(100% - calc(var(--res-unit)*6));
 top: calc(var(--res-unit)*3);
 left: calc(var(--res-unit)*7);
 padding: calc(var(--res-unit)*2);
 padding-bottom: calc(var(--res-unit)*1);
 color: aliceblue;
 font-size: calc(var(--res-unit)*1.2);
 z-index: 2;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center; 
}
.loadingscreencontourarea{
 width: calc(var(--res-unit)*50) !important;
 position: absolute !important;
 right: calc(var(--res-unit)*7);
 top: calc(var(--res-unit)*3);
 z-index: 1;
 align-content: center;
 cursor: all-scroll;
 image-rendering: auto;
}
#mappic{
  height: calc(100vh - var(--res-unit)*6);
  object-fit:fill;
}
#map-instructions{
  position: absolute;
  right: calc(var(--res-unit)*7);
  bottom: calc(var(--res-unit)*3);
  width: calc(var(--res-unit)*50);
 color: aliceblue;
 font-size: calc(var(--res-unit)*1.2);
 z-index: 2;
 border: solid aliceblue calc(var(--res-unit)*0.1);
 background-color: black;
 display: flex;
 justify-content: space-around;
 user-select: none;
}
#LoadingScreenButtons{
  display: flex;
  justify-content: center;
  margin-top: calc(var(--res-unit)*2);
  align-items: center;
}
.LoadingScreenNavButton{
 background-color: transparent;
 border: none;
 padding: 0;
 width: calc(var(--res-unit)*3);
 height: calc(var(--res-unit)*1.5);
 transition: 0.1s;
 background: url('./Assets/arrow.svg') no-repeat;
 background-size: 50%;
 background-position: center;  
 position: relative;
 opacity: 0.5;
 transition: opacity 0.3s;
}
.LoadingScreenNavButtonActive{
 background-color: transparent;
 border: none;
 padding: 0;
 width: calc(var(--res-unit)*3);
 height: calc(var(--res-unit)*1.5);
 transition: 0.1s;
 background: url('./Assets/arrow.svg') no-repeat;
 background-size: 50%;
 background-position: center;  
 position: relative;
 opacity: 1.0;
 transition: opacity 0.3s;
}
.LoadingScreenNavButtonActive:hover{
  background: url('./Assets/greenarrow.svg') no-repeat;
  background-size: 50%;
  background-position: center;
  cursor: pointer;
}
#lefticon{
  transform: scaleX(-1);
}

.LoadingScreenStartButton{
  background-color: transparent;
  border: none;
  padding: 0;
  width: calc(var(--res-unit)*8);
  height: calc(var(--res-unit)*3);
  display: flex;
  align-content: center;
  color: aliceblue;
  transition: 0.3s;
  opacity: 0.2;
  font-size: calc(var(--res-unit)*2);
  line-height: calc(var(--res-unit)*3);
  align-items: center;
  justify-content: center;
 }
.LoadingScreenStartButtonLoaded{
  background-color: transparent;
  border: none;
  padding: 0;
  width: calc(var(--res-unit)*8);
  height: calc(var(--res-unit)*3);
  display: flex;
  align-content: center;
  color: aliceblue;
  transition: 0.1s;
  opacity: 1.0;
  font-size: calc(var(--res-unit)*2);
  line-height: calc(var(--res-unit)*3);
  align-items: center;
  justify-content: center;
 }
 .LoadingScreenStartButtonLoaded:hover{
  color: #B5D77F;
  cursor: pointer;
 }
#gamewrapper{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#gameaspectholder{
  width: calc(calc(100vh/9)*16);
  height: 100vh;
}
.GameCanvas{
  height: 100%; 
  width: 100%;
  display: hidden;
}

#mobile-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
#mobiletext {
  display: inline-block;
  font-size: 2.0vh;
  max-width: 50%;
  max-height: 50%;
  color: #c5dbff;
  user-select: none;
  z-index: 1;
  /*pointer-events: none;
  change back to none*/
  display: inline;
  text-align: center;
  white-space: pre-line; }
  #gamebutton{
    position: absolute;
    top:0%;
    left: 0%;
    z-index: 8;
  }



